<script setup lang="ts">
const { refreshCart } = useCart();
const { addMaasVoucher } = useMaasVoucher();
const { pushError, pushSuccess } = useNotificationsEx();

const loading = ref(false);

const addPromotionCodeHandler = async (e: Event, code: string) => {
  e.preventDefault();
  loading.value = true;

  try {
    const result = await addMaasVoucher(code);
    await refreshCart();
    promoCode.value = "";
    loading.value = false;

    for (const message of result.messages) {
      if (result.status === "success") {
        pushSuccess(message.message);
      } else {
        pushError(message.message);
      }
    }

    if (result.status !== "success" && result.messages.length === 0) {
      pushError("Gutschein konnte nicht angewendet werden.");
    }
  } catch (error: any) {
    loading.value = false;
    let messages = (error?.response?.data?.messages ?? []);

    for (const message of messages) {
      pushError(message.message);
    }

    if (messages.length === 0) {
      pushError("Gutschein konnte nicht angewendet werden.");
    }
  }
};

const promoCode = ref("");
</script>

<template>
  <div class="@container">
    <form class="@lg:flex items-center justify-between gap-x-12" method="post" @submit="(e) => addPromotionCodeHandler(e, promoCode)">
      <SharedFormLabel label="Gutschein-Code" class="my-0 text-small" for="promoCode" />
      <div class="flex-1 flex gap-0">
        <SharedFormInput
          id="promoCode"
          v-model="promoCode"
          type="text"
          name="promoCode"
          :placeholder="$t('form.promoCodePlaceholder')"
          class="border-e-none rounded-r-none focus:outline-none focus:border-2 focus:border-maas-border-primary uppercase"
        />
        <SharedFormButton
          type="submit"
          layout="primary"
          data-testid="cart-checkout-link"
          class="flex gap-4 items-center rounded-l-none"
          :loading="loading"
        >
          Einlösen
        </SharedFormButton>
      </div>
    </form>
  </div>
</template>
